<template>
  <main role="main">
    <div class="block">
      <div class="mb-2">
        <span style="font-size: 1rem">按地区筛选 </span>
        <v-region @values="regionChange" :area="false" class="form-control"></v-region>
      </div>
      <div class="row">
        <div class="col-md-4" v-for="loc in locations" :key="loc.id">
          <!-- <a href @click="redirect(loc.url)">{{loc.nick}}</a> -->
          <router-link class="router-link" :to="{ name: 'GovHomePage', params: { alias: loc.url }}">{{loc.province + (loc.city || '') + (loc.town || '')}}</router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  name: "List",
  data: () => {
    return {
      locations: [],
      regions: []
    }
  },
  created() {
  },
  mounted() {
    this.$emit('updateInfo', { nick: '农证宝' })
    this.getLocations()
  },
  methods: {
    getLocations: function(region) {
      var that = this

      let url = '/web/govsite/list'
      if (region) {
        url += '?'
        if (region.province) {
          url += 'province=' + region.province.value
        }
        if (region.city) {
          url += '&city=' + region.city.value
        }
      }
      axios.get(this.$BASE_URL + url).then(function (resp) {
        console.log('/web/govsite/list')
        console.log('axios', resp.data.data)
        that.locations = resp.data.data
      }).catch(function (error) {
        console.log('axios error', error)
      })
    },
    // redirect: function(url) {
    //   console.log('redirecting', url)
    //   this.$router.push({name: 'GovHomePage', params: { alias: url }})
    // },
    regionChange(data) {
      this.getLocations(data)
    }
  }
}

</script>

<style scoped lang="scss">
a.router-link {
  font-size: 1rem;
  line-height: 2.5rem;
}

.form-control.v-region {
  border: none;
  display: inline;
}
</style>
